import Glide from '@glidejs/glide';



// Initialisation slider.

export default function slider () {
 let slider = document.querySelector('.glide');
  if (slider) {
    let glide = new Glide('.glide', {
      type: 'carousel',
      autoplay: false,
      time: 2000,
      perView: 1,
      dots: true,
    });
  glide.mount();
  }
}

document.addEventListener('DOMContentLoaded', function(){
  let slides = document.getElementsByClassName('glide__slide');
  if(slides.length <= 1) {
    document.querySelector('.glide__arrow--right').remove();
    document.querySelector('.glide__arrow--left').remove();
    return false
  } else {
    // Create button for slider.
    (function () {
      let slides = document.querySelectorAll('.glide__slide');

      for (let i = 0; i < slides.length; i++) {
        let button = document.createElement('button');
        button.classList.add('glide__bullet');
        button.setAttribute('data-glide-dir', `=${i}`);

        document.querySelector('.glide__bullets').appendChild(button);
      }
    }());

    slider();
  }
});


