export default function search(e) {
  let searchInput = this.querySelector('input');
  let searchText = this.querySelector('label');

  if (e.target.closest('input')) {
    this.classList.add('active');
    searchInput.focus();
  }
}

document.querySelector('.search').addEventListener('click', search);

document.addEventListener('click', function (e) {
  if (!e.target.closest('.search')) {
    document.querySelector('.search').classList.remove('active')
  }
});

document.addEventListener('keyup', function (e) {
  if(e.keyCode === 13) {
    document.querySelector('.search input[type="text"]').value = '';
  }
});

document.addEventListener('DOMContentLoaded', function () {
  document.querySelector('.search input[type="text"]').value = '';
});