// Filter apartments.
let activeCategory;

document.addEventListener('click', function(e){
    if (e.target.closest('.arichive-years-list .list-item')) {
        let items = e.target.parentNode.nextElementSibling.children;
        let categories = e.target.parentNode.children;

        // Clear rest category.
        for (i = 0; i < categories.length; i++) {
            categories[i].classList.remove('active');
        }
        // Get category.
        e.target.classList.add('active');
        activeCategory = e.target.getAttribute('data-filter')

        for (i = 0; i < items.length; i++) {
            items[i].style.display = 'none';
            if (items[i].getAttribute('data-item') === activeCategory) {
                items[i].style.display = 'block';
                // items[i].classList.add('active');
            }
            if (activeCategory === 'all') {
                items[i].style.display = 'block';
            }
        }
    }
});