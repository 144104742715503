// Show/hide button up.
let btnUp = document.querySelector('.btn-up');

if (btnUp) {
    btnUp.addEventListener('click', function (e) {
        e.preventDefault();
        let top = document.querySelector('header');
        top.scrollIntoView({block: 'start', behavior: 'smooth'})
    });
}

export default function showBtnUp() {
    if(document.querySelector('body').getBoundingClientRect().top < -300) {
        btnUp.classList.add('active');
    } else {
        btnUp.classList.remove('active');
    }
}

document.addEventListener('scroll', showBtnUp);
document.addEventListener('DOMContentLoaded', showBtnUp);