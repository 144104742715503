// Mobile button.
(function() {
  let dotsMenu = document.querySelector(".dots");
  let menu = document.querySelector('.header-menu');
  
  dotsMenu.addEventListener("click", function() {
   document.querySelector('html').classList.toggle('non-scroll');
    menu.classList.toggle('active');
    return dotsMenu.classList.toggle("on");
  });

}).call(this);



let submenuList = document.querySelectorAll('.list-item.menu-item-has-children a');

for (let i = 0; i < submenuList.length; i++) {
  const submenu = submenuList[i];
  submenu.addEventListener('click', function () {
    this.classList.toggle('active');
    this.nextElementSibling.classList.toggle('active');
  });
}