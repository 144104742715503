  // Add phone mask
  function addPrefixToPhone() {
    let $this = this;
    let value = $this.value;

    if (value.length < 4 || !/^\+380/.test(value)) {
      $this.value = '+380';
    }
    // Replace not digital symbols.
    if (value.slice(0, 4)) {
        $this.value = '+380' + (Number(value.slice(4)) || '');
    }
    // Limit max length.
    if (value.length > 13) {
        $this.value = $this.value.slice(0, 13);
    }
  }

  
  let form = document.getElementsByClassName('contacts-callback');

  let phone_field = document.querySelector('input[name="phone"]');
  let email_field = document.querySelector('input[name="email"]');
  let name_field = document.querySelector('input[name="name"]');
  let theme_field = document.querySelector('input[name="theme"');
  let msg_field = document.querySelector('textarea[name="message"]');
  let file_input = document.querySelector('input[name="file"]');

  phone_field ? phone_field.addEventListener('focus', addPrefixToPhone ) : false
  phone_field ? phone_field.addEventListener('keyup', addPrefixToPhone ) : false

  // Validate fields
  form.length > 0 ? form[0].addEventListener('submit', function (e) {
    e.preventDefault();

    let validate_phone = false;
    let validate_email = false;
    let validate_file_type = true;
    let validate_file_size = true;


    if (/^\+380\d{9}$/.test(phone_field.value)) {
      validate_phone = true;
      phone_field.style.border = '1px solid #e3e3e3';
    } else {
      phone_field.style.border = '1px solid red';
    }


    if(email_field.value.length >= 1) {
      validate_email = true;
      email_field.style.border = '1px solid #e3e3e3';
    } else {
      email_field.style.border = '1px solid red';
    }

    if(file_input.value.length > 1) {
      let fileExt = file_input.value.replace(/\s/g, "");
      let fileType = fileExt.substr(file_input.value.lastIndexOf('\\') + 1).split('.')[1];
      let fileSize = file_input.files[0].size / 1024 / 1024 || 0;

      if(fileSize < 3) {
        validate_file_size = true;
        file_input.style.border = '1px solid #e3e3e3';
      } else {
        validate_file_size = false;
        file_input.style.border = '1px solid red';
      }
      
      if(fileType === 'doc' || fileType === 'docx' || fileType === 'pdf' || fileType === 'txt') {
        validate_file_type = true;
        file_input.style.border = '1px solid #e3e3e3';
      } else {
        validate_file_size = false;
        file_input.style.border = '1px solid red';
      }
  
    }

   
   

    // Return validate inputs.
    if(validate_phone && validate_email && validate_file_type && validate_file_size) {

      const data = {'email' : email_field.value || '' ,
                    'name'  : name_field.value || '',
                    'phone' : phone_field.value || '',
                    'theme_field' : theme_field.value || '',
                    'message' : msg_field.value || '',
                    'file' : file_input.files[0] || ''
                    };
                    
      fetch('/wp-content/themes/visnyk/send_email.php', {
            method: 'POST',
            processData: false,
            contentType: false,
            body: data
          })
          .then((data) => {
            document.querySelector('#done').innerText = 'Успішно відправлено!';
            name_field.value = '';
            email_field.value = '';
            phone_field.value = '';
            theme_field.value = '';
            msg_field.value = '';
            file_input.value = '';
          })
          .catch((err)=>
            document.querySelector('#done').innerText = 'Не відправлено!')
        }
    }) : false